var header = '';

(function($, viewport) {

  header = {

    init: function(){
      header.$header = $('#header');
      header.bindHamburgerEvents();
      header.bindIntranetEvents();
    },

    bindHamburgerEvents: function(){
      header.hMenuOpen  = header.$header.find('.hamburger-menu-open');
      header.hMenuClose  = header.$header.find('.hamburger-menu-close');

      header.hMenuOpen.click(function(){
        header.$header.addClass('menu-open');
      });

      header.hMenuClose.click(function(){
        header.$header.removeClass('menu-open');
      });
    },

    bindIntranetEvents: function() {
      header.LIntranetOpen  = $('.link-intranet-open');
      header.hIntranetClose  = header.$header.find('.hamburger-intranet-close');

      header.LIntranetOpen.click(function(e){
        e.preventDefault();

        header.$header.addClass('intranet-open');
      });

      header.hIntranetClose.click(function(){
        header.$header.removeClass('intranet-open');
      });
    }

  };


})(jQuery, ResponsiveBootstrapToolkit);
